export default {
  black: '#000',
  blue900: '#01163E',
  blue800: '#323B69',
  blue700: '#0086CB',
  blue600: '#5CB5FE',
  pink900: '#DE0C4B',
  pink800: '#FF5778',
  grey400: '#fff',
  grey500: '#EAEAEA',
  grey600: '#B0AFAF',
  green100: '#89D747',
  red100: '#DD4646',
  lilac900: '#7A1FA0',
  lilac800: '#AD52D2',
  purple900: '#6600F7',
  purple800: '#A248FF',
  yellow900: '#FCCC00',
  yellow800: '#FFFF50',
};
