import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

import { Container, Profile } from './styles';

import logo from '~/assets/provi.svg';

export default function Header() {
  const dispatch = useDispatch();
  const signed = useSelector(state => state.auth.signed);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <nav>
        <img src={logo} alt="Provi" />
        <Link to="/checkouts">CHECKOUTS</Link>
      </nav>

      {signed && (
        <aside>
          <Profile>
            <div>
              <strong>Partner</strong>

              <button type="button" onClick={handleSignOut}>
                Logout
              </button>
            </div>

            <img
              src="https://api.adorable.io/avatars/285/papo@provi.com.br"
              alt="Partner profile"
            />
          </Profile>
        </aside>
      )}
    </Container>
  );
}
