import styled from 'styled-components';

export const ActionsMenu = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  button {
    margin-right: 10px;
  }
`;

export const LoadingLine = styled.div`
  width: 50%;
  height: 16px;
  align-self: center;
  border-radius: 8px;
`;
