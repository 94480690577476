import styled from 'styled-components';

import colors from '~/styles/colors';

export const Wrapper = styled.div`
  height: 100%;
  background: ${colors.grey500};

  div.children {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
