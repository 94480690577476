import React, { useState, useEffect } from 'react';
import {
  MdPerson,
  MdInfo,
  MdCheck,
  MdAssignmentTurnedIn,
  MdAttachMoney,
  MdRemove,
  MdCancel,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import Shimmer from 'react-shimmer-effect';

import Container from '~/components/Container';
import Content from '~/components/Content';
import Title from '~/components/Title';
import Button from '~/components/Button';
import { Table } from '~/components/Table';
import { ActionsMenu, LoadingLine } from './styles';

import colors from '~/styles/colors';
import api from '~/services/api';

export default function Checkouts() {
  const [checkouts, setCheckouts] = useState([]);
  const [selectedCheckout, setSelectedCheckout] = useState();
  const [loading, setLoading] = useState(false);
  const [requestingUpdateStatus, setRequestingUpdateStatus] = useState(false);

  async function loadCheckouts() {
    try {
      setLoading(true);
      const response = await api.post('v2/checkouts?quantity=100');

      setCheckouts(response.data);
    } catch (_) {
      toast.error('Something went wrong while loading checkouts');
    }

    setLoading(false);
  }

  async function updateSelectedCheckoutStatus(status) {
    try {
      setRequestingUpdateStatus(true);

      let errorMsg = '';

      try {
        await api.put(`checkouts/${selectedCheckout}`, { status });
      } catch (error) {
        errorMsg = error && error.response && error.response.data.message;
      } finally {
        const indexOfSelectedCheckout = checkouts.indexOf(
          checkouts.find(checkout => checkout.id === selectedCheckout)
        );

        if (errorMsg) {
          toast.error(errorMsg);
          return;
        }

        const tempCheckoutArray = Object.assign(checkouts);
        tempCheckoutArray[indexOfSelectedCheckout] = {
          id: selectedCheckout,
          ...checkouts[indexOfSelectedCheckout],
          status,
        };

        setCheckouts([]);
        setCheckouts(tempCheckoutArray);
      }

      toast.success(`Checkout status successfully updated to ${status}`);
    } catch (error) {
      toast.error(
        (error && error.response && error.response.data.message) ||
          'There was a unknown error'
      );
    }

    setRequestingUpdateStatus(false);
  }

  useEffect(() => {
    loadCheckouts();
  }, []);

  return (
    <Container>
      <Title>
        <h1>Existing checkouts</h1>

        <div>
          <Button
            text={loading ? 'Loading...' : 'Reload existing checkouts list'}
            icon={MdRefresh}
            disabled={loading}
            onClick={loadCheckouts}
          />
        </div>
      </Title>

      <Content>
        <ActionsMenu>
          <Button
            type="button"
            icon={MdPerson}
            text="Logged"
            color={colors.blue700}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() => updateSelectedCheckoutStatus('logged')}
          />
          <Button
            type="button"
            icon={MdInfo}
            text="Finished fill basic info"
            color={colors.blue600}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() =>
              updateSelectedCheckoutStatus('finished_fill_basic_info')
            }
          />
          <Button
            type="button"
            icon={MdInfo}
            text="Finished fill document"
            color={colors.blue600}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() =>
              updateSelectedCheckoutStatus('finished_fill_document')
            }
          />
          <Button
            type="button"
            icon={MdCheck}
            text="Approved"
            color={colors.green100}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() => updateSelectedCheckoutStatus('approved')}
          />
          <Button
            type="button"
            icon={MdAssignmentTurnedIn}
            text="Signed"
            color={colors.yellow800}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() => updateSelectedCheckoutStatus('signed')}
          />
          <Button
            type="button"
            icon={MdAttachMoney}
            text="Paid first installment"
            color={colors.yellow900}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() =>
              updateSelectedCheckoutStatus('paid_first_installment')
            }
          />
          <Button
            type="button"
            icon={MdRemove}
            text="Canceled"
            color={colors.pink800}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() => updateSelectedCheckoutStatus('canceled')}
          />
          <Button
            type="button"
            icon={MdCancel}
            text="Denied"
            color={colors.pink900}
            disabled={!selectedCheckout || loading || requestingUpdateStatus}
            onClick={() => updateSelectedCheckoutStatus('denied')}
          />
        </ActionsMenu>

        <div>
          <Table>
            <thead>
              <tr>
                <th>SELECT</th>
                <th>ID</th>
                <th>STATUS</th>
                <th>CPF</th>
                <th>SENDS WEBHOOKS</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td>
                    <Shimmer>
                      <LoadingLine />
                    </Shimmer>
                  </td>
                  <td>
                    <Shimmer>
                      <LoadingLine />
                    </Shimmer>
                  </td>
                  <td>
                    <Shimmer>
                      <LoadingLine />
                    </Shimmer>
                  </td>
                  <td>
                    <Shimmer>
                      <LoadingLine />
                    </Shimmer>
                  </td>
                  <td>
                    <Shimmer>
                      <LoadingLine />
                    </Shimmer>
                  </td>
                </tr>
              ) : (
                checkouts.map(checkout => (
                  <tr key={checkout.id}>
                    <td>
                      <Button
                        icon={
                          selectedCheckout === checkout.id ? MdCancel : null
                        }
                        text={
                          selectedCheckout === checkout.id
                            ? 'Selected'
                            : 'Select this one'
                        }
                        color={
                          selectedCheckout === checkout.id
                            ? colors.green100
                            : colors.grey600
                        }
                        onClick={() => {
                          if (selectedCheckout === checkout.id) {
                            setSelectedCheckout(null);
                          } else {
                            setSelectedCheckout(checkout.id);
                          }
                        }}
                      />
                    </td>
                    <td>{checkout.id}</td>
                    <td>{checkout.status}</td>
                    <td>{checkout.CPF}</td>
                    <td>{checkout.isCheckout ? 'Yes' : 'No'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </Content>
    </Container>
  );
}
