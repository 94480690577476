import styled from 'styled-components';

import colors from '~/styles/colors';

export const Container = styled.div`
  height: 80px;
  padding: 0 30px;
  background: ${colors.blue900};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);

  h1 {
    color: ${colors.grey400};
  }

  nav {
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      padding-right: 25px;
      border-right: 2px solid ${colors.grey600};
    }
  }

  a {
    font-weight: bold;
    color: ${colors.grey400};
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: 0;
      font-weight: bold;
      color: ${colors.grey600};
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: ${colors.grey400};
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: ${colors.grey600};
    }
  }

  img {
    border: 2px solid ${colors.grey400};
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
`;
