import styled, { css } from 'styled-components';
import { darken, desaturate } from 'polished';

import colors from '~/styles/colors';

export const ButtonWrapper = styled.button`
  background: ${props => {
    if (props.disabled === false) {
      return props.color;
    }

    return desaturate(50, props.color);
  }};
  height: 48px;
  border: 0px;
  color: ${colors.grey400};
  border-radius: 30px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => {
      if (!props.disabled) {
        return darken(0.03, props.color);
      }

      return desaturate(50, props.color);
    }};

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  div {
    display: flex;
    align-items: center;
    padding: 14px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: rgba(0, 0, 0, 0.1);

    svg {
      color: ${colors.grey400};
      font-size: 20px;
    }
  }

  span {
    color: ${colors.grey400};
    flex: 1;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin: 0 12px;
  }
`;
