import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSend } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Card, TextInput } from './styles';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '~/assets/provi-small.svg';

const schema = Yup.object().shape({
  token: Yup.string().required('Authentication token is required'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  useEffect(() => {
    document.title = 'Provi Sandbox Checkout - Sign In';
  }, []);

  function handleSubmit({ token }) {
    dispatch(signInRequest(token));
  }

  return (
    <Card>
      <img src={logo} alt="Provi" />

      <Form onSubmit={handleSubmit} schema={schema}>
        <TextInput
          name="token"
          id="token"
          type="text"
          placeholder="Your authentication token here"
          label="YOUR AUTHENTICATION TOKEN"
        />

        <Button
          type="submit"
          icon={MdSend}
          text={loading ? 'Loading...' : 'Submit authentication'}
        />
      </Form>
    </Card>
  );
}
