import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Checkouts from '~/pages/Checkouts';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" isPrivate={false} exact component={SignIn} />
      <Route path="/checkouts" isPrivate exact component={Checkouts} />

      <Route
        path=""
        isPrivate={false}
        component={() => <h1>Page not found</h1>}
      />
    </Switch>
  );
}
